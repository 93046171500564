import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'app-text', loadChildren: './app-text/app-text.module#AppTextPageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'registration', loadChildren: './registration/registration.module#RegistrationPageModule' },
  { path: 'instructions', loadChildren: './instructions/instructions.module#InstructionsPageModule' },
  { path: 'pakkette', loadChildren: './pakkette/pakkette.module#PakkettePageModule' },
  { path: 'listen', loadChildren: './listen/listen.module#ListenPageModule' },
  { path: 'buy', loadChildren: './buy/buy.module#BuyPageModule' },
  { path: 'download', loadChildren: './download/download.module#DownloadPageModule' },
  { path: 'resetpassword', loadChildren: './resetpassword/resetpassword.module#ResetpasswordPageModule' },
  { path: 'profile', loadChildren: './profile/profile.module#ProfilePageModule' },
  { path: 'shop', loadChildren: './shop/shop.module#ShopPageModule' },
  { path: 'shop-confirm-order', loadChildren: './shop-confirm-order/shop-confirm-order.module#ShopConfirmOrderPageModule' },
  { path: 'toc-modal-page', loadChildren: './toc-modal-page/toc-modal-page.module#TocModalPagePageModule' },
  { path: 'make-payment', loadChildren: './make-payment/make-payment.module#MakePaymentPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
