import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DataService } from './services/data.service';

import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public data: DataService,
    public router: Router,
    public toastCtrl: ToastController,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then( () => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      if( typeof( localStorage.session ) === 'undefined' || typeof( localStorage.userID ) === 'undefined' ){
          //console.log( "checking session" );
          this.fLogOut();
      } else{
          //check session
          //console.log( "verifying session" );
          this.fToast( '...verifying session...' );
          this.fVerifySession().then( res => {
              res.subscribe( response => {
                //console.log( response );

                if( response.result === 'success' ){
                    // overwrite data for security
                    const data = response.data;

                    localStorage.userID     = data.userID;
                    localStorage.firstname  = data.firstname;
                    localStorage.lastname   = data.lastname;
                    localStorage.email      = data.email;
                    localStorage.cellphone  = data.cellphone;
                } else{
                    this.fLogOut();
                }

              });
          });
      }

    });
  }

  fLogOut(){
    localStorage.clear();
    this.gotoPage( 'login' );
  }


  async fVerifySession(){
    return await this.data.getData( {  class   : 'users',
                                        method  : 'fCheckSession',
                                        data    : { userID  : localStorage.userID, session: localStorage.session  } } );
  }

  gotoPage( name: string ){
    // console.log( "xxx : " + name );
    this.router.navigateByUrl( name );
  }

  async fToast( msg ){
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 1500,
      color: 'primary',
      position: 'top',
      cssClass: 'toastFont'
    });
    toast.present();
  }
}
