import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public collections  = [];
  public sandbox      = false;
  public shopData     = {};

  constructor(  public http: HttpClient ) {}

  /**
   * This functions check if there is an Internet connection
   * 
   * @returns {Promise<boolean>} - True if there is an Internet connection, false if not.
   */
  async fCheckInternet() {

    try {
      const result = await this.getData({
        class: 'config',
        method: 'testInternet',
        data: null,
      }).toPromise();
      
      return result.result === "success";
    } catch(error){
      /** If there is no Internet connection, show the error message */
      return false;
    }
  }

  async getLanguages() {

    // tslint:disable-next-line:align
    return await this.getData( {  class:  'config',
                                  method: 'languages',
                                  data:    { test : 'result' } } )
                  .subscribe( data => {
                    
                    const arrTemp = data.languages.split(',');

                    const arrLanguages = [];
                    
                    for ( let i = 0; i < arrTemp.length; i++ ) {
                        const arr2 = arrTemp[i].split('|');
                        arrLanguages.push( {  languageCode : arr2[0],
                                              languageName : arr2[1] } )
                    }

                    localStorage["languages"] = JSON.stringify(arrLanguages);
                    return true;
                  });
  }

  /**
   * This function is the main data API calling method
   * 
   * @param {any} input - The data structure to use for calling the API.
   *                    This is an object with the following structure:
   *                   {  class: string, 
   *                      method: string, 
   *                      data: object }
   *  @returns {Observable<any>} - The data returned from the API call.
   */
  getData( input: any ): Observable<any>{

      try{
          // Break the input object into the AP class, method, and data to forward
          const requestClass  = input.class;
          const requestMethod = input.method;
          const rData         = input.data;

          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            })
          };

          const data = [  { auth  : 'aep',
                            data  : { securityKey : 'annaemmpod' } },
                          { class : requestClass,
                            method: requestMethod,
                            data  : JSON.stringify( rData ) }];

          return this.http.post<any>( `https://annaemmpod.co.za/app.php`,
                                      data,
                                      httpOptions );
      } catch(error){
        console.log( "API connection getData error." + error );
      }
      
  }

  /**
   * This function is called from the shop page to get the shop config
   * 
   * @param {string} shopConfig - The key under which the data will be stored in localStorage.
   * @param {any} value - The data to be stored.
   * @throws {Error} If localStorage is not available or if the storage quota is exceeded.
 
   * @returns true
   */
  async setupShopData() {
    try{
      return await this.getData( {   class   : 'config',
                        method  : 'fetchShopConfig',
                        data    : null } )
                        .subscribe( result => {

                        for( let i = 0; i < result.length; i++ ){
                          for (const [key, value] of Object.entries(result[i])) {
                            this.shopData[key] = value;
                          }
                        }

                        localStorage.setItem( 'shopConfig', btoa( JSON.stringify( this.shopData ) ) );
                      });
    } catch(error){
        console.log( "Problem storing shopConfig " + error );
    }  
  }
}

// function timeout(arg0: number): import("rxjs").OperatorFunction<any, unknown> {
//   throw new Error('Function not implemented.');
// }

